import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TwoColSection from "../../components/ui/sections/TwoColSection";
import Contact from "../../components/global/Contact";

const EcommercePage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "e-commerce.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 640)
        }
      }
    }
  `);

  // const pros = [
  //   {
  //     title: "Mobile-first",
  //     description:
  //       "Nasze e-commercy doskonale radzą sobie z urządzeniami mobilnymi. Wiemy, jak ważne jest to zarówno dla klientów, jak i wyszukiwarek.",
  //     icon: "ti-mobile",
  //   },
  //   {
  //     title: "Design",
  //     description:
  //       "Wierzymy, że dobrze zaprojektowany sklep internetowy, to sklep, w którym klienci kupują częściej. Dbamy więc o ich wrażenia.",
  //     icon: "ti-layout",
  //   },
  //   {
  //     title: "Wydajność i analityka",
  //     description:
  //       "Przed wypuszczeniem sklepu do sieci sprawdzamy jej optymalizację i wydajność. Mierzymy Twoją stronę, aby wiedzieć gdzie i co możemy poprawić.",
  //     icon: "ti-bar-chart",
  //   },
  //   {
  //     title: "Uporządkowane zarządzanie",
  //     description:
  //       "Z nami dokładnie wiesz co, kiedy i za ile. Stawiamy na porządek w projekcie, zwinne zarządzanie i procedury.",
  //     icon: "ti-calendar",
  //   },
  // ];

  // const process = [
  //   {
  //     title: "Koncept strony",
  //     description:
  //       "Wspólnie myślimy o przeznaczeniu, głównych celach oraz funkcjonanościach strony internetowej. Tworzymy plan działania i zakładamy system zarządzania zadaniami, żeby ten plan spełnić.",
  //   },
  //   {
  //     title: "Podpisanie e-umowy",
  //     description:
  //       "Lubimy ekologię, więc nie marnujemy papieru na umowy. Do rozpoczęcia współpracy wystarczy kliknięcie linku w mailu potwierdzające chęć zaakceptowania umowy.",
  //   },
  //   {
  //     title: "Projektowanie szablonów",
  //     description:
  //       "Przenosimy nasze ustalenia z głowy do programów graficznych i projekujemy design widoków strony. Powstaje design interfejsu oraz koncepcje ułatwiające klientowi obcowanie z naszym produktem.",
  //   },
  //   {
  //     title: "Programowanie",
  //     description:
  //       "Mieliśmy czas zastanowić się jaka technologia i rozwiązanie będzie optymalne dla Naszej koncepcji. Czas więc wytoczyć działa i kodujemy.",
  //   },
  //   {
  //     title: "Przedstawienie wersji MVP",
  //     description:
  //       "Przez cały proces developmentu aktualna wersja produktu będzie dostępna dla Ciebie do wglądu. Szybciej więc zbierzemy feedback dotyczący działania poszczególnych funkcjonalności.",
  //   },
  //   {
  //     title: "Testowanie",
  //     description:
  //       "Przez cały okres developmentu oraz przed wdrożeniem, sklep jest przez nas dokładnie testowany.",
  //   },
  //   {
  //     title: "Procedura przedwdrożeniowa",
  //     description:
  //       "Przed wdrożeniem sklepu należy podpiąć do niej narzędzia analityczne, upewnić się, że przez Google nie będą indeksowane żadne niechciane treści i wiele innych kwestii, które mamy spisane.",
  //   },
  //   {
  //     title: "Wdrożenie",
  //     description:
  //       "Proces deploymentu sklepu na serwerze z wykorzystaniem naszych narzędzi jest szybki i sprawny.",
  //   },
  //   {
  //     title: "Utrzymanie sklepu",
  //     description:
  //       "Przez cały proces dążymy do tego, żebyś był zadowolony z naszych usług i z chęcią kontynuwał współpracę przy utrzymaniu i rozwijaniu Twojego sklepu internetowego",
  //   },
  // ];

  return (
    <Layout pageTitle="E-commerce">
      <section className="container mx-auto px-5">
        <ImageSection
          subtitle="Tworzymy fajne doświadczenia zakupowe"
          title="Twoje zakupy to nasz biznes"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Sklep internetowy to tylko narzędzie do sprzedaży w internecie.
            Zadbajmy jednak o to, żeby tego narzędzia używało się przyjemnie i
            żeby spełniało ono dobrze swoją rolę.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Nasze rozwiązania staramy oprzeć o prosty i przyjemny proces
            zamawiania, a także intuicyjną obsługę nawet trudnych biznesowo
            przypadków.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Razem zaprojektujemy ściężkę klienta od wejścia na Twoją stronę do
            pochwalenia się zakupionym produktem w mediach społecznościowych. Bo
            o to chodzi w e-commerce.
          </p>
        </ImageSection>
      </section>

      <TwoColSection title="Tworzymy sklepy internetowe, które sprzedają">
        <p>
          Wykonujemy projekty e-commerce od początku do końca. Dobry e-commerce
          to połączenie najnowszej technologii, umiejętności ciągłego
          dostosowywania się do rosnących wymagań klientów oraz szczypta
          osobowości. Pomożemy Ci przejść wszystkie te kroki od wyboru platformy
          dla Twojego sklepu po pierwsze i kolejne zamówienia. Nasze projekty
          opieramy zależnie od wymagań o PrestaShop, Shopify lub nasze własne
          rozwiązania. Lubimy oryginalne pomysły na interakcję z klientami.
        </p>
      </TwoColSection>

      <TwoColSection title="Nasze mocne strony" background="gray">
        <h3 className="text-black text-2xl font-semibold mb-3">
          Korzystając z naszych rozwiązań zyskasz:
        </h3>
        <ul className="mb-5 list-disc list-inside">
          <li>nowoczesny projekt graficzny</li>
          <li>jeden system dla sklepu w internecie i aplikacji mobilnej</li>
          <li>funkcjonalności skrojone na miarę</li>
          <li>zoptymalizowane i szybkie rozwiązanie</li>
          <li>strukturę przyjazną SEO</li>
          <li>fachowe doradztwo</li>
        </ul>
        <h3 className="text-black text-2xl font-semibold mb-3">
          Nasze kompetencje:
        </h3>
        <ul className="list-disc list-inside">
          <li>tworzenie sklepów internetowych – PrestaShop, Shopify</li>
          <li>platformy B2B</li>
          <li>integracja z systemami zewnętrznymi</li>
          <li>dostosowanie sklepu do wymagań biznesowych</li>
          <li>tworzenie responsownych sklepów internetowych</li>
          <li>obsługa i rozwój sklepów</li>
        </ul>
      </TwoColSection>
      <Contact />
    </Layout>
  );
};

export default EcommercePage;
